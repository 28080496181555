.o-stack{
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
}

.o-stack__item{
  grid-column: 1;
  grid-row: 1;
}

.o-stack--top{
  z-index: 2;
}
