.c-nav{  }

.c-nav__list, .c-nav__list-item {
	padding: 0;
	margin: 0;
	list-style: none;
}

.c-nav__list-item {
	display: inline-block;
}

.c-nav__link {
	padding: 0.5em;
	border: none;
	text-decoration: none;
}

