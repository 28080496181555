
/* Helpers - these produce no output */
@import 'helpers/*';

/* These do */
@import 'generic/*';
@import 'objects/*';
@import 'components/*';

:root{
  --color-fg:   white;
  --color-bg:   black;

  --skip-fg:    var(--color-fg);
  --skip-bg:    var(--color-bg);

  --text-body:  'Eesti', serif;
  --text-alt:   'Eksell Display', sans-serif;

  --text-size-small: 0.75rem;

  --transition: transform .8s cubic-bezier(0,0,.2,1),opacity 1.2s cubic-bezier(.22,.61,.36,1);

  --wrapper-max-width: 1280px;

  --full-height: 100vh;

}

html{
  font-size: responsive;

  min-font-size: 19px;
  max-font-size: 24px;

  lower-font-range: 420px;
  upper-font-range: 1280px;

  background-color: var(--color-bg);
  color: var(--color-fg);
  -webkit-font-smoothing: antialiased;
  font-family: var(--text-body);
  line-height: 1.3;
}

a { color: currentColor; }


img{
  display: block;
  width: 100%;
}

iframe{
  height: 100%;
  width: 100%;
  /* object-fit: cover;*/
}

h1,h2,h3,h4,h5,h6{
  font-weight: normal;
}

.hidden-always{ display: none; }

.show-on-mobile{
  display: none;
}

.u-mxa{
  margin-left: auto;
  margin-right: auto;
}

.lazyload-img,
.lazyload,
.lazyloading {
  transition: transform .8s cubic-bezier(0,0,.2,1), opacity 1.2s cubic-bezier(.22,.61,.36,1);
  opacity: 0;
}

.lazyloaded{
  opacity: 1;
}

.js-in-view{
  --delay: var(--stagger, 0);
  transition: transform .4s cubic-bezier(0,0,.2,1),opacity .7s cubic-bezier(.22,.61,.36,1);
  transform: none;
  opacity: 1;
  transition-delay: calc( (var(--delay) / 2) * 300ms);
}

.not-in-view { opacity: 0; }

[data-scrolldir=up] .not-in-view {
  transform: translateY(-1rem);
}

[data-scrolldir=down] .not-in-view {
  transform: translateY(1rem);
}

/* [data-scrolldir=up] .not-in-view.in-view--image, */
/* [data-scrolldir=down] .not-in-view.in-view--image{ */
/*   transform: transformY(1rem) */
/* } */


@media (--for-phone-only){
  .hidden-on-mobile{
    display: none;
  }
  .show-on-mobile{
    display: block;
  }
}

.font-eksell{
  font-family: 'Eksell Display';
  font-weight: normal;
}

.text-ingress{
  font-size: 1.2rem;
  font-weight: 300;
  max-width: 50ch;
  @media (--for-phone-only){
    max-width: 70%
  }
}

.text-center{ text-align: center; }

.text-uppercase{
  text-transform: uppercase;
  letter-spacing: 0.075em;
  font-size: var(--text-size-small);
}

.flex-center{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.flex-column{
  display: flex;
  flex-direction: column;
}

.flex-v-center{
  display: flex;
  align-items: center;
}

.flex-reverse{
  flex-direction: row-reverse;
}

.flex-s-between{
  justify-content: space-between;
  width: 100%;
}

.text-h1 { font-size: 4rem; }
.text-h2 { font-size: 3rem; }
.text-h3 { font-size: 2rem; }

@media (--for-phone-only){
  .text-h1 { font-size: 2rem; }
  .text-h2 { font-size: 2rem; }
  .text-h3 { font-size: 1.5rem; }
}

.text-h1,
.text-h2{
  line-height: 1.1;
}

.text-h1 {
  max-width: 18ch;
  display: block;
  margin: 1.5rem auto;
  white-space: initial;
}

.text-right {
  text-align: right;
}

.m0{ margin: 0; }
.mg{ margin-bottom: 1rem; }

a{
  color: inherit;
  text-decoration: none;
}

a[target*="_"]{
  text-decoration: underline;
}

.c-header{
  color: white;
}
.c-logo{
  @media (--for-phone-only){
    width: 200px;
    height: 78px;
  }
}

.c-header__logo{
  fill: currentColor;
  width: 8em;
}

.c-header__button{ display: none; }

@media (--for-portrait-tablet-and-up){

  .c-nav {
    position: fixed;
    top: 0;
    height: 100vh;
    display: flex;
    z-index: 1000;
    padding: 0.5em;
    pointer-events: all;
    transition: 750ms opacity;
    opacity: 1;
  }
  .c-nav.hide {
    opacity: 0;
  }
  .c-nav--case {
    left: 0;
  }

  .c-nav__list {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .c-nav__link{
    padding: 0 1em;
    text-transform: uppercase;
    opacity: 0.5;
    transition: 250ms opacity;
  }

  .c-nav__link:hover{ opacity: 1; }

  .c-main-nav{
    position: fixed;
    top: 50%;
    right: 0;
    transform: translateX(50%) translateY(-50%);
    z-index: 1000;
  }

  .c-main-nav__inner{
    transform: rotate(90deg) translateY(50%);
    transform-origin: center;
    width: 600px;
    max-width: 100vh;
    display: flex;
    justify-content: space-around;
    padding: 1.5rem;
    font-size: var(--text-size-small);
    letter-spacing: 0.075em;
    opacity: 1;
    transition: 250ms opacity;
    &.hide {
      opacity: 0;
    }
  }

  .c-menu-item{
    text-align: center;
  }

  .c-menu-item--prop:nth-child(2){
    order: 3;
  }

  .c-case{
    position: relative;
    width: 3.5em;
    height: 3.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--text-size-small);
    letter-spacing: 0.075em;

    line-height: 1;
    opacity: 0;
    animation-name: staggered;
    animation-duration: 700ms;
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    animation-fill-mode: both;
    animation-delay: calc( (var(--stagger) / 2) * 300ms);
    pointer-events: all;
  }

  .c-case:hover{
    opacity: 1;
  }

  .c-case__number {
    opacity: 0.5;
    transition: 500ms opacity;
    letter-spacing: 0.075em;
  }

  .c-case__hover {
    position: absolute;
    left: 3em;
    opacity: 0;
    transition: 500ms opacity;

  }

  .c-case:hover .c-case__hover {
    opacity: 1;
  }

  .c-nav__item.disabled .c-case__number {
    opacity: 0.2;
  }

  .c-nav__item.disabled .c-case__circle {
    display: none;
  }

  .c-case:hover .c-case__number,
  body:not(.case-nav-is-open) .c-nav__item.active .c-case__number,
  body:not(.case-nav-is-open) .c-nav__item.load-active .c-case__number{
    opacity: 1;
  }

  .c-case__title{
    position: absolute;
    left: 100%;
    opacity: 0;
    transform: rotateX(90deg);
    white-space: nowrap;
    transition: all 300ms ease;
    display: none;
  }


  .c-case__circle{
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    height: 100%;
    width: 100%;
  }


  .c-case__circle circle{
    stroke-width: 0.5px;
    transform: scale(0.6);
    transition: all 300ms ease-out;
    transform-origin: center;
    color: transparent;
    stroke: currentColor;
    will-change: transform, stroke-dashoffset;
    opacity: 0.8;
  }

  .c-case:hover .c-case__circle circle,
  .c-nav__item.active .c-case__circle circle
  /*.c-nav__item.load-active .c-case__circle circle*/ {
    color: white;
    transform: none;
  }
  /*
  .case-nav-is-open .c-nav__item.load-active .c-case__circle circle,
  .case-nav-is-open .c-nav__item.active .c-case__circle circle {
    color: transparent;
  }
  */

  .c-hero{
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    overflow: hidden;
    white-space: nowrap;
    color: white;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 900;
    pointer-events: none;
  }

  .c-hero__text{
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    z-index: 10;
    text-align: center;
  }

  .c-hero__slides {

    transform: translateY( calc(var(--scroll) * -100vh));
    transition: all 300ms ease;
    opacity: 0;
    cursor: url(/assets/symbols/x.gif), auto;

  }

  .case-nav-is-open .c-hero__slides {
    opacity: 1;
    will-change: transform, opacity;
    pointer-events: all;

  }

  .c-nav__item.active .c-case__number {
    opacity: 1;
  }

  /*
  .c-nav{
  transform: none;
  transition: transform 400ms ease;
  }

  .in-transition .c-nav{
  pointer-events: none;
  transform: translateX(-100%);
  }
   */



.c-hero__slide{
  min-width: 100vw;
  min-height: 100vh;
  max-height: 100vh;
  display: flex;
  justify-content: center;
  /* transition: all 300ms ease; */
}

.c-hero__content{
  /* display: grid; */
  /* grid-template-columns: 1fr; */
  /* grid-template-rows: 1fr; */
  /* align-items: center; */
  /* justify-content: center; */
  /* position: relative; */
  /* transform: scale(.8); */
  /* transition: all 500ms ease; */

  display: grid;
  width: 80vw;
  height: 80vh;
  margin: 10vh;
  align-items: center;
  justify-content: center;
  position: relative;
  /* transform: scale(.8);*/
  transition: all 250ms ease;
  &:hover {
    transform: scale(1.05)
  }
}

/*
.in-transition .c-hero__slide.is-active {
opacity: 0;
transition: var(--transition);
}
 */


.c-hero__body{
  grid-column: 1;
  grid-row: 1;
  z-index: 100;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  white-space: normal;
}


.c-hero__image{
  width: 100%;
  height: 100%;
  object-fit: cover;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}

}

@media (--for-phone-only){

  .c-header {
    display: flex;
    flex-direction: column;

    position: fixed;
    bottom: 100%;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 1000;

    transition: var(--transition);
    transform: none;
    will-change: transform;
    background-color: black;
      height: var(--full-height);

  }

  .c-header__button{
    -webkit-appearance: none;
    background: transparent;
    border: 1px solid #fff;
    position: fixed;
    z-index: 1000;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 99em;
    color: #fff;
    display: inline-block;
    line-height: 0;
    padding: 1rem;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }

  .c-header__button:before,
  .c-header__button:after {
    /*
    content: "";
    display: block;
    height: 2px;
    width: 2em;
    background-color: white;
    transition: transform 400ms ease;
    */
  }

  .c-header__button:before{
    margin-bottom: -1px;
    transform: translateY(-4px);
  }

  .c-header__button:after{
    margin-top: -1px;
    transform: translateY(4px);
  }

  .case-nav-is-open .c-header__button:before{
    transform: rotate(45deg);
  }

  .case-nav-is-open .c-header__button:after{
    transform: rotate(-45deg);
  }

  .c-header__button:focus{ outline: none; }

  /*
  [data-scrolldir=up] .c-header{
  transform: none;
  }

  [data-scrolldir=up] .o-main{
  transform: translateY(300px);
  }
   */

  .case-nav-is-open .c-header{
    transform: translateY(100%);
  }

.case-nav-is-open .o-main{
  transform: translateY(150px);
}

.c-main-nav{
  /* padding: .5em; */
  text-align: center;
  order: 2;
  font-size: var(--text-size-small);

}
.c-nav__item{
  text-transform: uppercase;
  /* padding: 0.5em; */
  letter-spacing: 0.075em;
}
.c-nav__link{
  padding: 1em 0.5em;
  display: inline-block;

}

.c-nav{ display: none; }


.c-hero__slides{
  width: 100vw;
  display: flex;
  flex-wrap: nowrap;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  padding: 2em 0;
  padding: 2em;
}

.c-hero__slide {
  min-width: 75vw;
  margin-right: 2em;
  position: relative;
  height: calc(var(--full-height) - 12em);
  overflow: hidden;
}

.c-hero__slides:after {
  content: "";
  display: inline-block;
  min-height: 100%;
  min-width: 1em;
  position: relative;
}

.c-hero__image{
  height: 50vh;
  object-fit: cover;
}

.c-hero__image img {
  height: 100%;
  object-fit: cover;
}

.c-hero__slide.disabled img {
  opacity: 0.5;
}

.c-hero__body {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  padding: 1em;
  text-align: center;
}

.c-hero__heading{
  font-size: 2rem;
  margin-left: auto;
  margin-right: auto;
}

}



@keyframes staggered{
  from {
    opacity: 0;
    transform: translateX(-200%);
  }
  to{
    transform: none;
    opacity: 1;
  }
}

.c-hero__mask{
  position: fixed;
  height: 100%;
  width: 100%;
}

.c-content{
  display: block;
  width: 100%;
  position: relative;
  overflow: hidden;
  opacity: 1;
}

.o-main{
  transition: var(--transition);
  /*height: 100%;*/
  padding-bottom: 1px;
}

.case-nav-is-open .o-main, .in-transition .o-main{
  opacity: 0.0;
}

.c-content__block {
  max-width: 1400px;
  width: calc(100% - 8em);
  margin-right: auto;
  margin-left: auto;

  margin-top: 25vh;
  margin-bottom: 25vh;

  /* min-height: 80vh; */

  display: flex;
  align-items: center;
  justify-content: center;
  @media (--for-phone-only){
    width: 100%;
    min-height: auto;
    padding: 0 1em;
    margin-top: 8vh;
    margin-bottom: 8vh;
  }
}


.c-content__footer{
  margin-top: 5rem;
}

.c-content__block--full{
  max-width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
  margin-top: 0;
  margin-bottom: 0;
  display: grid;
  width: auto;
  @media (--for-phone-only){
    padding: 0;
    height: var(--full-height);
  }
}

.c-content__block--small {
  margin-top: 20vh;
  margin-bottom: 10vh;
}

.c-content__block--full-width{
  max-width: 100%;
  margin-top: 25vh;
  margin-bottom: 25vh;
  @media (--for-phone-only) {
    margin-top: 8vh;
    margin-bottom: 8vh;
  }
}

.c-content__block--full-height{
  min-height: 100vh;
}

.c-content__text{
  max-width: 66ch;
  margin: 0 auto;
}

.c-content__hero{
  width: 100%;
  height: 100%;
  max-width: none;
  object-fit: cover;
}
.c-content__hero--video{
  display: flex;
  align-items: center;
}

.skew{ transform: rotate(-0.5deg)}

.c-grid{
  display: grid;
  grid-gap: 40px;
}

.c-grid--no-gap{
  grid-gap: 0px;
}

.c-grid--single .c-content__image + .c-content__image{
  margin-top: 5em;
  @media (--for-phone-only){
    margin-top: 0;
  }
}

.c-grid--single{
  max-width: 60vw;
  width: 100%;
  @media (--for-phone-only){
    max-width: none;
  }
}

.c-grid--gallery{
  grid-template-columns: repeat(3, 1fr);
  /* grid-template-rows: repeat(2, 1fr); */
  @media (--for-phone-only){
    grid-template-columns: 1fr;
    grid-template-rows: 1fr
  }
}
.c-grid--gallery p{
  /* display: none; */
}

.c-grid--double{
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(1, 1fr);

  @media (--for-phone-only){
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }
}


.c-content__image{
  overflow: hidden;
}

.c-content__constrain{
  max-width: 25ch;
}

.c-content__cite{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 2em;
}

.c-content__cite-line{
  overflow: hidden;
  height: 1px;
  width: 190px;
  transform: translateY(-2px);
  display: inline-block;
  margin: 0 1em;
}

.c-content__cite-line:before{
  position: absolute;
  content: "";
  height: 1px;
  width: 100%;
  background-color: currentColor;
  transform: none;
  transition: transform 500ms ease;
  transition-delay: 200ms;
}

.not-in-view .c-content__cite-line:before{
  transform: translateX(100%);
}




.c-slider{
  max-width: 80vw;
  margin-left: auto;
  margin-right: auto;
  white-space: nowrap;
  @media (--for-phone-only) {
    max-width: 100vw;
  }
}

/* .c-slider__slide:first-child{ */
/*   min-width: auto; */
/*   padding-right: 5em; */
/* } */

.c-slider {
  position: relative;
  /* margin-top: 5rem; */
  /* margin-bottom: 5rem; */
}

.c-slider__frame {
  width: 100%;
  position: relative;
  /* overflow: hidden; */
  white-space: nowrap;
}

.c-slider__slides {
  display: flex;
  flex-wrap: nowrap;
}

.c-slider__slide {
  position: relative;
  display: inline-flex;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  padding: 20px;
  opacity: 0.5;
  transition: opacity 300ms ease;
  /* transition: var(--transition); */
  /* transform: translateX(10%); */
  @media (--for-phone-only){
    padding: 0;
    min-width: 100%;
    max-width: 100%;
    transform: none;
  }
}

.c-slider__slide.active{
  opacity: 1;
}
.c-slider__caption{
  transition: var(--transition);
  opacity: 0;
  transform: translateY(1rem);
}
.c-slider__slide.active .c-slider__caption{
  opacity: 1;
  transform: none;
}

.c-slider__nav{
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  /* top: 0; */
  /* bottom: 0; */
  /* height: 100%; */
  display: flex;
  justify-content: space-around;
  align-items: center;
  @media (--for-phone-only){
    margin-top: 1rem;
  }
}

.c-slider__current-slide{
  font-size: 0.5em;
}

.c-slider__button{
  color: white;
  background-color: transparent;
  border: none;
}
.c-slider__button:active{
  transform: scale(0.9);
}
.c-slider__button:focus{
  outline: none;
}
.js_next{ cursor: e-resize; }
.js_prev{ cursor: w-resize; }

.c-pagination-list{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.c-pagination{
  display: flex;
  align-items: center;
  flex-direction: row;
  @media (--for-phone-only) {
    max-width: 50%;
    flex-direction: column;
    align-items: none;
  }
}

.c-content__block--pagination {
  @media (--for-phone-only) {
    padding: 0 0 10vh;
  }
}
.c-pagination > *{
  pointer-events: none;
}

.c-pagination img {
  transition: 250ms transform;
  will-change: transform;
}

.c-pagination:hover img {
  transform: scale(1.035);
}

.c-pagination--reverse {
  @media (--for-portrait-tablet-and-up) {
    flex-direction: row-reverse;
  }
}

.c-pagination__text{
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 1rem;
  @media (--for-phone-only) {
    order: 2;
  }
}

.c-pagination__image{
  max-width: 200px;
  @media (--for-phone-only){
    max-width: 50vw;
    flex-grow: 1;
  }
}

.c-line--half{
  width: 50px;
  margin: 0 0.5em;
  @media (--for-phone-only) {
    width: 20px;
  }
}

.c-transition{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2000;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  pointer-events: none;
  opacity: 0;
  background: #000;
  color: #fff;
  font-family: var(--text-alt);
  font-size: 1.5em;
}

.c-transition__circle {
  position: absolute;
  top: calc(50% - 1em);
  left: calc(50% - 1em);
  width: 2em;
  height: 2em;
  animation: circleAnim 1s infinite linear;
  transform-origin: 50% 50%;
  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 50%;
  }
  &:before {
    border-radius: 200px 200px 0 0;
    background-image: linear-gradient(to right, rgba(255,255,255, 0.5), rgba(255,255,255,0.8))
  }
  &:after {
    border-radius: 0 0 200px 200px;
    background-image: linear-gradient(to right, rgba(255,255,255, 0.5), transparent);
    top: 50%;
  }

}

.c-transition__inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 1.9em;
  height: 1.9em;
  line-height: 1.7;
  background: black;
  border-radius: 99em;
}

@keyframes circleAnim {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.c-transition svg{
  width: 100vw;
  height: 100vh;
  opacity: 0.4;
  animation: loading 1.5s infinite;
}



.c-transition__logo {
  @media (--for-phone-only){
    display: none;
  }
}

.case-nav-is-open .c-transition #rectangle{
  will-change: transform;
}

.c-transition text{
  font-family: var(--text-alt);
  font-size: 4em;
}

.c-button{
  padding: 0.5em;
  border: none;
  background-color: transparent;
  text-decoration: underline;
  color: white;
  cursor: pointer;
  margin-top: 2em;
  display: inline-block;
}


.c-about {
  display: grid;


  @media (--for-portrait-tablet-and-up) {
    grid-template-columns: 1fr 1fr;
    margin-top: -10vh;
  }

  /* grid-template-rows: repeat(6, minmax(max-content, auto)); */

  grid-gap: 1em 2em;
  max-width: 38rem;
  grid-auto-flow: dense;
  & .text-h1 {
    margin: 0;
  }
  & p {
    margin-top: 0;
    &:last-child {
      margin-bottom: 0;
    }
  }
  & a {
    text-decoration: none;
    border-bottom: 1px solid #555;
    transition: 250ms border-color;
    &:hover {
      border-color: #fff;
    }
  }
  @media (--for-phone-only) {

    & .text-h1 {
      font-size: 3em;
      margin-bottom: 3vh;
      text-align: center;
    }
    &:after {
      content: '';
      position: fixed;
      bottom: 0;
      height: 10vh;
      background: linear-gradient(rgba(0,0,0,0), #000, #000);
      width: 100%;
    }


  }
}

.c-about__image {
  @media (--for-phone-only) {
    grid-row-start: 2;
    grid-row-end: 2;
    margin-bottom: 1em;
  }
}

.c-about__text  {
  margin-top: 0;
  @media (--for-portrait-tablet-and-up) {
    grid-column: 2/3;
  }
  color: rgba(255, 255, 255, 0.8);
}

.c-about__text--fill{
  @media (--for-portrait-tablet-and-up) {
    grid-column: 1/3;
  }
  max-width: calc(100% - 16em);
  transform: translateX(5em);
}

.c-about__text--offset{
  margin-top: 7em;
}



.c-table{
  display: grid;
  grid-template-columns: min-content minmax(min-content, 13em) 1fr;

  grid-gap: 1em 2em;
  padding: 1em 0;
  @media (--for-phone-only) {
    grid-gap: 1em 1em;
  }
}

.c-table__cell:nth-child(3n){
  text-align: right;
  color: rgba(255, 255, 255, 0.5);
}


/*  */
/* .c-about__text { */
/*   grid-column: 2/3; */
/* } */
/*  */
/* .c-about__text:nth-child(1) { */
/*     grid-row: 1 / span 2; */
/*     grid-column: auto; */
/* } */
/*  */
/* .c-about__text:nth-child(2){ */
/*     grid-row-start: 2; */
/*     grid-row-end: span 2; */
/* } */
/*  */
/* .c-about__text:nth-child(3) { */
/*   transform: translateX(50%); */
/*   grid-column: 1/2; */
/*   grid-row-start: 4; */
/* } */
/*  */
/* .c-about__text:nth-child(4) { */
/*   grid-row-end: span 2; */
/*   grid-row-start: 5; */
/* } */
/*  */
/* .c-about__image { */
/*   grid-row-end: end; */
/*   grid-column: 1 / 2; */
/* } */

.c-video{
  position: relative;
  width: 100%;
  font-size: var(--text-size-small);
  letter-spacing: 0.075em;
  text-transform: uppercase;
}

.c-video__inner{
  display: block;
  width: 100%;
  position: relative;
  height: 0;
  padding-bottom: 56.25%;
  & iframe {
    position: absolute;
    top: 0;
    left: 0;

  }
}

.c-video__controls {
  display: flex;
  padding-top: 1em;
  z-index: 10;
  color: white;
  position: absolute;
  top: 0%;
  left: 0%;
  height: 100%;
  width: 100%;
  transition: 250ms opacity;
  opacity: 0;
  @media (--for-phone-only) {
    opacity: 1;
  }
}

.c-video:hover .c-video__controls {
  opacity: 1;
}

.c-video__play {
  flex: 1;
  position: absolute;
  top: 0%;
  left: 0%;
  height: 100%;
  width: 100%;
}

.c-video__play button {
  background: none;
  color: currentColor;
  border: 0;
  padding: 0;
  opacity: 0.25;
  transition: 250ms opacity;
  letter-spacing: 0.075em;
  text-transform: uppercase;
}

.c-video__play .active {
  opacity: 1;
}

.c-video__play button:focus {
  outline: 0;
}

.c-video__unmute{
  flex: 1;
  text-align: center;
  z-index: 2;
  position: absolute;
  left: 0;
  width: 100%;
  bottom: 2rem;
  @media (--for-phone-only) {
    bottom: 1rem;
  }

}

.sound-off {
  display: none;
}

.c-video__sound.active .sound-off {
  display: inline-block;
}

.c-video__sound.active .sound-on {
  display: none;
}

.video-is-unmuted .c-video__unmute{
  /* pointer-events: none; */
}

.c-video__sound {
  opacity: 0.35;
  transition: 250ms opacity;
  @media (--for-phone-only) {
    opacity: 1;
  }
  &:hover {
    opacity: 1;
  }
  pointer-events: all;
  cursor: pointer;
}

.c-video__button{
  transition: all 300ms ease;
  cursor: pointer;
}


.video-is-unmuted .unmuted, .muted{
  opacity: 1;
}

.video-is-unmuted .muted, .unmuted{
  opacity: 0.2;
}



.outer {
  position: relative;
  width: 100%;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: calc((9 / 16) * 100%);
  }
  & > .inner {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.c-contact{
  margin-top: auto;
}

.c-contact__row:last-child{ margin-top: 1.5em; }


body {

}
.case-nav-is-open {
  background: #000;
}

.active-stroke {
  opacity: 1;
  position: relative;
}

.active-stroke:after {
  background: url(/assets/img/stroke.svg) no-repeat;
  position: absolute;
  top: 15%;
  left: 0;
  content: '';
  width: 100%;
  height: 250%;
  display: none;
}

@media (--for-portrait-tablet-and-up) {
  .active-stroke:after {
    top: -75%;
  }
}








.personal--outer {
  position: relative;
  margin-bottom: 20vh;
}
.personal {
  max-width: 80vw;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-rows: 30vh;
  margin: 100px auto;
  grid-gap: 20px;
  grid-auto-flow: dense;
}

@media only screen and (min-height: 600px) {
  .personal {
    grid-auto-rows: 20vh;
  }
}

@media only screen and (min-height: 700px) {
  .personal {
    grid-auto-rows: 15vh;
  }
}

.personal:hover .personal-item {
  outline-color: #fff;
}

.personal-item {
  /* grid-column-start: var(--column-start, auto); */
  grid-column-end: var(--column-end, auto);
  /* grid-row-start: var(--row-start, auto); */
  grid-row-end: var(--row-end, auto);
  position: relative;
  transition: 250ms outline-color;
  outline: 1px dotted transparent;

}

.personal-item .text {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}

.personal-item--square {
  --column-start: span 2;
  --column-end: span 2;
  --row-start: span 2;
  --row-end: span 2;
}

.personal-item--tall {
  --column-start: span 1;
  --column-end: span 1;
  --row-start: span 2;
  --row-end: span 2;
}

.personal-item--tall.personal-item--square {
  --column-start: span 2;
  --column-end: span 2;
  --row-start: span 2;
  --row-end: span 2;
}

.personal-item--huge {
  --column-start: span 3 !important;
  --column-end: span 3 !important;
  --row-start: span 3 !important;
  --row-end: span 3 !important;
}


.personal img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@font-face {
  font-family: 'RunWilds';
  src: url('/assets/fonts/run.eot?#iefix') format('embedded-opentype'),
     url('/assets/fonts/run.woff') format('woff'),
     url('/assets/fonts/run.ttf')  format('truetype')
}

.personal--text {
  font-family: 'RunWilds', fantasy;
  z-index: 1;
  font-size: 2em;
  text-align: center;
  line-height: 0.9;
}

.img-caption {
  font-size: 0.75em;
  opacity: 0.7;
  letter-spacing: 0.02em;
  margin-top: 1em;
}
.img-caption p {
  margin: 0;
}

.c-grid--singelFullWidth .img-caption {
  text-align: center;
  padding-left: 2em;
  padding-right: 2em;
}

.c-skip {
  display: none;
}


.c-content__text table {
  margin-top: 2em;
  text-align: left;
  & td {
    padding: .5em 2em .5em 0;
    border-bottom: 1px solid #999;
  }
  & td:first-child {
    color: #999;
  }
  & tr:last-child td {
    border: 0;
  }
}

.c-about__last {
  padding-bottom: 20vh;
}

.arrow {
  position: absolute;
  bottom: 1rem;
  width: 1.5rem;
  height: 1.5rem;
  border: 0 solid #fff;
  border-radius: 99em;
  left: 50%;
  transform: translateY(-50%);


  & svg {
    width: 0.7em;
    height: 0.7em;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
  }
}


.font-eksell strong {
  font-weight: 100 !important;
}